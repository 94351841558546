import hillo from 'hillo'
import GlobalSettings, { GlobalConfigLang } from '@/GlobalSettings'
import dayjs from 'dayjs'

// import i18n from '@/i18n'

export async function loadDashboardData (dateRange) {
  const [startDate, endDate] = dateRange
  return await hillo.get('DashboardData.php', {
    startDate: startDate,
    endDate: endDate,
    lang: GlobalConfigLang.lang,
  })
}

export async function loadServantList () {
  return (await hillo.get('Servant.php')).content.map(s => ({
    ...s,
    filterSet: 'servant',
  }))
}

export async function loadTables (filter) {
  return (await hillo.get('Tables.php', {
    op: 'currentInfos',
    lang: GlobalSettings.getLang(),
    ...filter,
  })).content.filter(it => parseInt(it.sectionId) !== 6)
}

export async function loadBillListForServant (pw = null, dateRange) {
  const [date, endDate] = dateRange
  return (await hillo.get('BackendData.php?op=mobileV3StatWithLang', {
    pw,
    date,
    endDate,
  })).content
}

export async function getAllServantInfo (dateRange) {
  return await Promise.all((await loadServantList())
    .map(async s => await loadBillListForServant(s.password, dateRange)))
}

export function comparator (a, b) {
  if (parseFloat(a) < parseFloat(b)) {
    return -1
  }
  if (parseFloat(a) > parseFloat(b)) {
    return 1
  }
  return 0
}

export async function getISPInfo () {
  return await hillo.get('https://ipapi.co/json/')
}

export async function getWeatherInfo () {
  // OpenWeather.com | 10000k Calls per Month
  const keyList = ['f7c1985ef7e270b413d536851b832ef4', 'ae2278279d368b11648f89478e4756e9', '809b574f0b0debf5d1c2d8b0ec89c727',
    '0dc0b677b071c699219287df33164f9c', '4198b8b0528f15040ca47e316fe2e9d5', '586e7d76a5dace3efc3a709d40751b89', 'f4e54f8bbfbf25095363aaff8465a239', '1315e7a0a63b9438b099c2081b1b9784', '2a2a923b45e47bde71827db7b0c0037e']
  const openWeatherApikey = keyList[dayjs().millisecond() % keyList.length]
  const ISPinfo = await getISPInfo()
  const [lat, lon] = [ISPinfo.latitude, ISPinfo.longitude]
  const weather = (await hillo.get('https://api.openweathermap.org/data/2.5/weather',
    { lat, lon, appid: openWeatherApikey }))
  return {
    ...weather, ISPinfo: ISPinfo,
  }
}
